import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        noData: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30%',
            maxWidth: '260px',
            '& .title': {
                marginBottom: '14px',
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            },
            '& .description': {
                color: theme.palette.secondary[600],
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            },
        },
        noMeasuredData: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '350px',
            '& .title': {
                marginBottom: '24px',
                color: theme.palette.primary.contrastText,
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                maxWidth: '294px',
            },
            '& .description': {
                color: theme.palette.secondary[600],
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                maxWidth: '294px',
            },
        },
        temperatureCheckBody: {
            boxSizing: 'border-box',
            padding: '0 5px',
            fontSize: '14px',
            margin: '23px 0 32px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
            width: '100%',
            maxWidth: '350px !important',
            '@media (max-width:400px)': {
                width: 'calc(100% - 24px) !important',
            },
        },
        measuredTemperatureSwitch: {
            marginBottom: '40px',
            maxWidth: '350px !important',
            '@media (max-width:400px)': {
                width: 'calc(100% - 24px) !important',
            },
        },
    };
});

export default useStyles;
