import React, { useCallback, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ApexOptions } from 'apexcharts';
import { momentAddOffset, utcDateToEpoch } from 'app/utils/tools';
import { useTheme } from '@material-ui/core';
import { useStyles } from './TemperatureChart.style';
import useScreenSize from '../../hooks/useScreenSize';
import MobileZoomBar from '../steps/TemperatureInfo/MobileZoomBar';
import { dateToNumber } from './useExcursionSeries/lib';
import useExcursionSeries from './useExcursionSeries';
import { generateTooltip } from './tooltipDefinition';

type Props = {
    data: any,
    containerType?: string,
    chartMounted?: (chart?: any, options?: any) => void,
    excursionOn?: string,
    leaseStartTimestamp?: string,
    leaseEndTimestamp?: string,
    shipmentStart: string,
    shipmentEnd: string,
    expectedLease?: string,
    labelData: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    },
    yMinMaxCustom?: { min: number, max: number},
}

export const ContainerMinMaxTemp = {
    DF: [-80, -60],
    F: [-25, -15],
    C: [2, 8],
    CRT: [15, 25],
};

const DOOR_COLORS = ['#fa19dd', '#fd8fef'];
const DANGER_COLOR = '#EDAE49';
const getDoorLine = (date: string, label = 'OPEN') => ({
    x: moment(date, 'YYYY-MM-DDTHH:mm')
        .utc(false)
        .add({ minute: moment().utcOffset() })
        .valueOf(),
    strokeDashArray: 0,
    borderWidth: 2,
    borderColor: DOOR_COLORS[label === 'OPEN' ? 0 : 1],
});

export const getCustomAnnotation = ({
    date,
    label,
    dateMask = 'DD-MM-YYYY HH:mm ZZ',
    offset = 0,
    offsetY = -10,
    dashed = false,
    background = 'white',
    utc = false,
}) => ({
    x: momentAddOffset(moment(date, dateMask).utc(utc)).valueOf(),
    borderColor: '#747474',
    strokeDashArray: dashed ? 2 : 0,
    borderWidth: dashed ? 1 : 2,
    label: {
        style: {
            color: '#747474',
            background,
        },
        offsetX: offset,
        offsetY,
        borderWidth: 0,
        text: label,
    },
});
const minZoomFactor = -0.4;
const maxZoomFactor = 0.48;
const zoomIncrement = 0.05;
const isUTC = false;
const TemperatureChart = ({
    data = [],
    containerType = 'CRT',
    chartMounted = () => {},
    labelData,
    shipmentStart,
    yMinMaxCustom,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const muiTheme = useTheme();
    const [touches, setTouches] = useState(null);
    const [tableViewControl, setTableViewControl] = useState<{
        zoom: number,
        position: number
    }>({
        zoom: 0,
        position: 0,
    });

    const adjustZoom = useCallback((direction = true) => {
        setTableViewControl(prev => ({
            ...prev,
            position: (prev.zoom - zoomIncrement < 0.001) ? 0 : prev.position,
            zoom: Math.min(
                Math.max(
                    prev.zoom + ((direction ? 1 : -1) * zoomIncrement),
                    minZoomFactor,
                ), maxZoomFactor,
            ),
        }));
    }, [setTableViewControl]);
    const adjustDelta = useCallback((direction = true) => {
        setTableViewControl(prev => ({
            ...prev,
            position: Math.min(
                Math.max(prev.position + (direction ? 1 : -1) * 0.05, -1),
                1,
            ),
        }
        ));
    }, [setTableViewControl]);

    const xAnnotations = useMemo(() => {
        return [];
    }, []);

    const doorAnnotations = useMemo(() => {
        if (!labelData?.dataTypes?.includes('DOOR')) {
            return [];
        }
        const doorIndex = labelData?.dataTypes?.findIndex((dt, i) => dt === 'DOOR'
            && ['UNSPECIFIED', 'NOT_APPLICABLE', 'DOOR'].includes(labelData?.positions?.[i]));

        if (doorIndex === -1) return [];

        return data
            .filter(it => it?.d?.[doorIndex])
            .map(it => ({ date: it?.t, label: it?.d[doorIndex] }))
            .map((door) => getDoorLine(door.date, door.label)).reverse();
    }, [data, labelData]);

    const xaxisCategories = useMemo(() => {
        return data.map(({ t }) => dateToNumber(t, false));
    }, [data]);

    const excursionRangeSeries = useExcursionSeries({
        temperatureRangeMax: ContainerMinMaxTemp?.[containerType]?.[1],
        temperatureRangeMin: ContainerMinMaxTemp?.[containerType]?.[0],
        temperatureData: data || [],
        enabled: true,
        inLocalTimeZone: false,
        tempIndex: labelData?.dataTypes?.findIndex((dt, i) => dt === 'TEMPERATURE'
            && labelData?.positions?.[i] === 'INTERNAL') || 0,
    });

    const series = useMemo(() => {
        if (!labelData?.dataTypes?.includes('TEMPERATURE')) {
            return [{
                name: 'Temperature [°C]',
                data: new Array(data.length).fill(null),
            }];
        }
        const temperatureIndex = labelData?.dataTypes?.findIndex((dt, i) => dt === 'TEMPERATURE'
            && labelData?.positions?.[i] === 'INTERNAL');

        if (temperatureIndex === -1) return [];
        const tempData = data
            .map(({ d }, dI) => ({
                x: xaxisCategories[dI],
                y: d[temperatureIndex] !== undefined && d[temperatureIndex] !== null ? d[temperatureIndex] : null,
            }));

        const temperatureSeries: any = [{
            name: `${t('TEMPERATURE.INTERNAL_TEMPERATURE')} [°C]`,
            data: tempData,
            type: 'rangeArea',
            opacity: 1,
            color: muiTheme.palette.primary['deepBlue'],
            stroke: {
                width: 2,
                curve: 'straight',
            },
        }];

        temperatureSeries.push(...excursionRangeSeries);

        return temperatureSeries;
    }, [data, containerType, labelData, xaxisCategories, excursionRangeSeries]);

    const { width, height } = useScreenSize();

    const isMobile = useMemo(() => {
        return width < 960;
    }, [width]);

    const minMax = useMemo(() => {
        const {
            zoom,
            position,
        } = tableViewControl;

        const [
            start,
            end,
        ] = [
            moment(shipmentStart || data?.[0]?.t).utc(false).valueOf(),
            moment().utc(false).valueOf(),
        ];

        if (!isMobile) {
            return {
                min: start,
                max: end,
            };
        }

        const length = end - start;
        const zoomedStart = start + length * zoom;
        const zoomedEnd = end - length * zoom;

        return {
            min: zoomedStart + length * (position * (1 - zoom)),
            max: zoomedEnd + length * (position * (1 - zoom)),
        };
    }, [isMobile, tableViewControl, shipmentStart, data]);

    const yMinMax = useMemo(() => {
        const internalIndex = labelData?.positions?.indexOf('INTERNAL');

        if (data.length === 0 || !labelData || internalIndex === null || internalIndex === -1) return {};
        const rawData = data.map(({ d }) => d[internalIndex]);

        rawData.push(ContainerMinMaxTemp[containerType]?.[0], ContainerMinMaxTemp[containerType]?.[1]);
        const sortedRawData = rawData.filter((value) => value !== null).sort((a, b) => a - b);

        return {
            min: sortedRawData[0] - 2,
            max: sortedRawData.at(-1) + 2,
        };
    }, [data, labelData, containerType]);

    const customTooltip = useCallback((opts) => generateTooltip(
        { theme: muiTheme, trans: t, opts },
    ), [muiTheme, t]);

    const options = useMemo<ApexOptions>((): ApexOptions => ({
        chart: {
            id: 'basic-bar',
            type: 'rangeArea',
            events: {
                mounted: chartMounted,
                legendClick(chart: any, seriesIndex?: number, options?: any) {
                    const {
                        globals,
                    } = options;
                    const {
                        seriesNames,
                        collapsedSeries,
                    } = globals;

                    const wasCollapsed = collapsedSeries?.some(it => it.index === seriesIndex);

                    if (!(collapsedSeries?.length === seriesNames.length - 1 && !wasCollapsed)) {
                        chart.toggleSeries(seriesNames[seriesIndex]);
                    }
                },
            },
            toolbar: {
                tools: {
                    zoomin: !isMobile,
                    zoomout: !isMobile,
                    pan: !isMobile,
                    zoom: !isMobile,
                    download: !isMobile,
                    selection: !isMobile,
                    reset: !isMobile,
                },
            },
            animations: {
                enabled: false,
                dynamicAnimation: {
                    enabled: false,
                },
                animateGradually: {
                    enabled: false,
                },
            },
            fontFamily: 'Roboto, serif',
        },
        legend: {
            show: true,
            position: 'top',
            offsetY: 30,
            horizontalAlign: 'center',
            showForSingleSeries: true,
            onItemClick: {
                toggleDataSeries: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            radius: 1,
            shape: 'circle',
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: isUTC,
            },
            categories: data?.map(({ t }) => utcDateToEpoch({ date: t, isUTC })),
            ...minMax,
        },
        yaxis: {
            ...(yMinMaxCustom || yMinMax),
        },
        stroke: {
            width: series.map(it => it?.stroke?.width || 2),
            curve: series.map(it => it?.stroke?.curve || 'straight'),
            dashArray: series.map(it => it?.stroke?.dashArray || 0),
        },
        annotations: {
            xaxis: [...xAnnotations],
            yaxis: [
                ...(ContainerMinMaxTemp[containerType]?.[0] ? [{
                    y: ContainerMinMaxTemp[containerType]?.[0],
                    borderColor: DANGER_COLOR,
                    borderWidth: 2,
                }] : []),
                ...(ContainerMinMaxTemp[containerType]?.[1] ? [{
                    y: ContainerMinMaxTemp[containerType]?.[1],
                    borderColor: DANGER_COLOR,
                    borderWidth: 2,
                }] : []),
            ],

        },
        tooltip: {
            shared: true,
            cssClass: classes.tooltip,
            custom: customTooltip,
        },
    }), [
        data,
        containerType,
        xAnnotations,
        doorAnnotations,
        chartMounted,
        series,
        isMobile,
        minMax,
        yMinMax,
        yMinMaxCustom,
    ]);

    return (
        <>
            <div
                className={classes.chartWrapper}
                onTouchStart={e => setTouches(e?.changedTouches)}
                onTouchEnd={e => {
                    if (tableViewControl.zoom < 0.001) return;
                    const startTouch = touches?.item(0);
                    const endTouch = e.changedTouches?.item(0);

                    if (Math.abs(endTouch.pageX - startTouch?.pageX) > 30) {
                        adjustDelta(endTouch.pageX - startTouch?.pageX < 0);
                    }
                }}
            >
                {
                    isMobile && (
                        <MobileZoomBar
                            classes={classes}
                            onZoomIn={adjustZoom.bind(null, true)}
                            onZoomOut={adjustZoom.bind(null, false)}
                            onLeft={adjustDelta.bind(null, false)}
                            onRight={adjustDelta.bind(null, true)}
                            onReset={setTableViewControl.bind(null, {
                                zoom: 0,
                                position: 0,
                            })}
                            showPan={tableViewControl.zoom > 0}
                        />
                    )
                }
                <ReactApexChart
                    type="rangeArea"
                    series={series}
                    options={options}
                    width={Math.min(width - 40, 600)}
                    height={Math.min(height * 0.3, 350)}
                />
            </div>
        </>
    );
};

export default TemperatureChart;
